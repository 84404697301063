/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

export default function CRMS() {
  return (
    <>
      <IndexNavbar fixed />
  
      <div data-aos="fade-in" className="page-title-area">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2 data-aos="zoom-out">PMS</h2>
              </div>
              </div>
            </div>
          </div>
        </div>
        <section className=" md:mt-10 pb-20 relative bg-blueGray-100">
       
       <div className="container mx-auto px-4 pb-20 pt-20">
         <div className="items-center flex flex-wrap">
           <div className="w-full md:w-5/12 ml-auto px-12 md:px-4">
             <div className="md:pr-12">
               <h3 data-aos="fade-up" className="text-3xl font-semibold">
               Project Management System
               </h3>
               <p data-aos="fade-up" className="mt-4 text-lg leading-relaxed text-blueGray-500">
               We developed an online software that helps to manage the day-to-day activities of a construction based project Management Company. A database driven application where the data is directly entered in Database via a secure web interface and reports can be generated on the basis of this.</p>
              
             </div>
           </div>

           <div data-aos="zoom-out-up" className="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0">
             <img
               alt="..."
               className="max-w-full"
               style={{
                 transform:
                   "scale(1) perspective(1040px) rotateY(-11deg) rotateX(2deg) rotate(2deg)",
               }}
               src={require("assets/img/pms.png").default}
             />
           </div>
         </div>
       </div>
     </section>
      
        <section className="relative py-20">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
    
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">     
              <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
                  <h3 data-aos="flip-down" className="text-3xl font-semibold">Master & Home</h3>
                  <ul className="list-none mt-6">
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Dashboard
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Alerts
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Employee Designations
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Employee Divisions
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Employee Master
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Item Category
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Sub-Contractor Master
                          </h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
     
                  <h3 data-aos="flip-down" className="text-3xl font-semibold">Sales</h3>
                  <ul className="list-none mt-6">
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Customer Management
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Site Management

                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Quotation Management and Negotiation
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Work Order and Client Payment Terms
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Payment Alerts
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Customer Receipts
                          </h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
                  <h3 data-aos="flip-down" className="text-3xl font-semibold">Work</h3>
                  <ul className="list-none mt-6">
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Site Allotment
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Item request

                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Request approval and item transfer
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Item return
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Sub-contractor allocation and management
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Sub-contractor payment
                          </h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>  
            </div>
            
          </div>
        </section>

        <section className="relative pb-20">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
    
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">     
              <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
                  <h3 data-aos="flip-down" className="text-3xl font-semibold">Purchase</h3>
                  <ul className="list-none mt-6">
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Vendors
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          RFQ
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          PO Management
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Item Receiving and add to Stock
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Stock Management
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Rented machinery and vehicle management
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Vendor payments
                          </h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              
              <div className="w-full md:w-4/12 px-4 ml-auto mr-auto">
                <div className="md:pr-12">
                  <h3 data-aos="flip-down" className="text-3xl font-semibold">Accounts</h3>
                  <ul className="list-none mt-6">
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          All Modules in accounts
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Integrated accounts from Time Attendance with Payroll System
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Integrated accounts from POS (Point of Sales)
                          </h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
                  <h3 data-aos="flip-down" className="text-3xl font-semibold">Reports</h3>
                  <ul className="list-none mt-6">
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          All Reports
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Reports from Time Attendance with Payroll System
                          </h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>   
            </div>
            
          </div>
        </section>

        <section className="relative pb-20">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
    
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">     
              <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
                  <h3 data-aos="flip-down" className="text-3xl font-semibold">Settings</h3>
                  <ul className="list-none mt-6">
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          User Management
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Roles and Profiles
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Alerts and Reminder Settings
                          </h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
  
            </div>
            
          </div>
        </section>
      <Footer />
    </>
  );
}
