/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

export default function CRMS() {
  return (
    <>
      <IndexNavbar fixed />

      <div data-aos="fade-in" className="page-title-area">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2 data-aos="zoom-out">Logistics Management System</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className=" md:mt-10 pb-20 relative bg-blueGray-100">

        <div className="container mx-auto px-4 pb-20 pt-20">
          <div className="items-center flex flex-wrap">
            <div className="w-full md:w-10/12 ml-auto px-12 md:px-4">
              <div className="md:pr-12">
                {/* <h3 data-aos="fade-up" className="text-3xl font-semibold text-center">
                Consignment Management System
                </h3> */}
                <div data-aos="zoom-out-up" className="md:w-8/12 m-auto">
                  <img
                    alt="..."
                    className="max-w-full"
                    style={{
                      transform:
                        "scale(1) perspective(1040px) rotateY(-11deg) rotateX(2deg) rotate(2deg)",
                    }}
                    src={require("assets/img/Makan_logistics_management.png").default}
                  />
                </div>
              </div>
            </div>


          </div>
        </div>
      </section>

      <section className="relative py-20">
        <div
          className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-white fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>

        <div className="container mx-auto px-4">
          <div className="flex flex-wrap">
            <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
              <div className="md:pr-12">
                <h3 data-aos="flip-down" className="text-3xl font-semibold">Shipments</h3>
                <ul className="list-none mt-6">
                  <li data-aos="zoom-out" className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-blueGray-500">
                          Add/edit/delete/list/print shipments
                          </h4>
                      </div>
                    </div>
                  </li>
                  <li data-aos="zoom-out" className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-blueGray-500">
                          Saved pickup management
                          </h4>
                      </div>
                    </div>
                  </li>
                  <li data-aos="zoom-out" className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-blueGray-500">
                          Saved dropoff management
                          </h4>
                      </div>
                    </div>
                  </li>
                  <li data-aos="zoom-out" className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-blueGray-500">
                          Requested shipment management
                          </h4>
                      </div>
                    </div>
                  </li>
                  <li data-aos="zoom-out" className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-blueGray-500">
                          Approved shipment management
                          </h4>
                      </div>
                    </div>
                  </li>
                  <li data-aos="zoom-out" className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-blueGray-500">
                          Closed shipment management
                          </h4>
                      </div>
                    </div>
                  </li>
                  <li data-aos="zoom-out" className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-blueGray-500">
                          Assigned shipment management
                          </h4>
                      </div>
                    </div>
                  </li>
                  <li data-aos="zoom-out" className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-blueGray-500">
                          Delivered shipment management
                          </h4>
                      </div>
                    </div>
                  </li>
                  <li data-aos="zoom-out" className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-blueGray-500">
                          Returned shipment management
                          </h4>
                      </div>
                    </div>
                  </li>
                  <li data-aos="zoom-out" className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-blueGray-500">
                          Bulk upload shipments
                          </h4>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
              <div className="md:pr-12">

                <h3 data-aos="flip-down" className="text-3xl font-semibold">Track Shipment</h3>
                <ul className="list-none mt-6">
                  <li data-aos="zoom-out" className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-blueGray-500">
                          Requested missions
                          </h4>
                      </div>
                    </div>
                  </li>
                  <li data-aos="zoom-out" className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-blueGray-500">
                          Received missions

                          </h4>
                      </div>
                    </div>
                  </li>
                  <li data-aos="zoom-out" className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-blueGray-500">
                          Closed missions
                          </h4>
                      </div>
                    </div>
                  </li>
                  <li data-aos="zoom-out" className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-blueGray-500">
                          Completed missions
                          </h4>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
              <div className="md:pr-12">

                <h3 data-aos="flip-down" className="text-3xl font-semibold">Management</h3>
                <ul className="list-none mt-6">
                  <li data-aos="zoom-out" className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-blueGray-500">

                          Vendor management
                          </h4>
                      </div>
                    </div>
                  </li>
                  <li data-aos="zoom-out" className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-blueGray-500">

                          Branch management

                          </h4>
                      </div>
                    </div>
                  </li>
                  <li data-aos="zoom-out" className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-blueGray-500">

                          Driver management
                          </h4>
                      </div>
                    </div>
                  </li>
                  <li data-aos="zoom-out" className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-blueGray-500">

                          Staff management
                          </h4>
                      </div>
                    </div>
                  </li>
                  <li data-aos="zoom-out" className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-blueGray-500">

                          Transaction management
                          </h4>
                      </div>
                    </div>
                  </li>
                  <li data-aos="zoom-out" className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-blueGray-500">

                          Vehicle management
                          </h4>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

        </div>
      </section>

      <section className="relative pb-20">
        <div
          className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-white fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>

        <div className="container mx-auto ml-auto mr-auto px-4">
          <div className="flex flex-wrap">
            <div className="w-full md:w-4/12 px-4">
              <div className="md:pr-12">
                <h3 data-aos="flip-down" className="text-3xl font-semibold">Settings</h3>
                <ul className="list-none mt-6">
                  <li data-aos="zoom-out" className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-blueGray-500">
                          Customer feedbacks
                          </h4>
                      </div>
                    </div>
                  </li>
                  <li data-aos="zoom-out" className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-blueGray-500">
                          Email integration
                          </h4>
                      </div>
                    </div>
                  </li>
                  <li data-aos="zoom-out" className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-blueGray-500">
                          SMS integration
                          </h4>
                      </div>
                    </div>
                  </li>
                  <li data-aos="zoom-out" className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-blueGray-500">
                          Notification integration
                          </h4>
                      </div>
                    </div>
                  </li>
                  <li data-aos="zoom-out" className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-blueGray-500">
                          Send sms to all customer when driver arrives at that zone
                          </h4>
                      </div>
                    </div>
                  </li>
                  <li data-aos="zoom-out" className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-blueGray-500">
                          send sms on delivery with a feedback form link
                          </h4>
                      </div>
                    </div>
                  </li>
                  <li data-aos="zoom-out" className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-blueGray-500">
                          Notify drivers on adding a shipment
                          </h4>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
              <div className="md:pr-12">
                <h3 data-aos="flip-down" className="text-3xl font-semibold">Shipment Settings</h3>
                <ul className="list-none mt-6">
                  <li data-aos="zoom-out" className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-blueGray-500">
                          Package type management
                          </h4>
                      </div>
                    </div>
                  </li>
                  <li data-aos="zoom-out" className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-blueGray-500">
                          Delivery time management
                          </h4>
                      </div>
                    </div>
                  </li>
                  <li data-aos="zoom-out" className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-blueGray-500">
                          Shipping rates management
                          </h4>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
              <div className="md:pr-12">
                <h3 data-aos="flip-down" className="text-3xl font-semibold">Reports</h3>
                <ul className="list-none mt-6">
                  <li data-aos="zoom-out" className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-blueGray-500">
                          Shipment reports
                          </h4>
                      </div>
                    </div>
                  </li>
                  <li data-aos="zoom-out" className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-blueGray-500">
                          Transaction reports
                          </h4>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            
          </div>

        </div>
      </section>
      <Footer />
    </>
  );
}
