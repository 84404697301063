/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

export default function Portolio() {
  return (
    <>
      <IndexNavbar fixed />
      

      
        
      
      <div data-aos="fade-in" className="page-title-area">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2 data-aos="zoom-out">Portfolio</h2>
              </div>
              </div>
            </div>
          </div>
      </div>
      <section className=" pb-40 relative">
        {/* <div
          className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-blueGray-100 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div> */}
        

        <div className="justify-center text-center flex flex-wrap mt-24">
          <div className="w-full md:w-6/12 px-12 md:px-4">
            <h2 data-aos="fade-up" className="font-semibold text-4xl">Latest Works</h2>
            <p data-aos="fade-up" className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500">
            Greeniit always follows the best and most efficient software engineering methods. We have a dedicated management team specialized in
information technology to manage and make sure that the whole development process meets industry standards.
            </p>
          </div>
        </div>
      </section>

      <section className="block relative z-1 pb-40 bg-blueGray-600">
        <div className="container mx-auto">
          <div className="justify-center flex flex-wrap">
            <div className="w-full spt-10 lg:w-12/12 px-4  -mt-24">
              <div className="flex flex-wrap">
                <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                    Makan
                  </h5>
                  <Link to="/portfolio">
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/login.jpg").default}
                      />
                    </div>
                  </Link>
                </div>

                <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                    Chatbot
                  </h5>
                  <Link to="/portfolio">
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/chatbot.jpg").default}
                      />
                    </div>
                  </Link>
                </div>

                <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                    Ephone
                  </h5>
                  <Link to="/portfolio">
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/landing.jpg").default}
                      />
                    </div>
                  </Link>
                </div>

                <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                    Mechanics
                  </h5>
                  <Link to="/portfolio">
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/mechanics.jpg").default}
                      />
                    </div>
                  </Link>
                </div>
                <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                  Makan ERP
                  </h5>
                  <Link to="/portfolio">
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/makan_erp.jpg").default}
                      />
                    </div>
                  </Link>
                </div>

                <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                    City shipping
                  </h5>
                  <Link to="/portfolio">
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/city-shipping.jpg").default}
                      />
                    </div>
                  </Link>
                </div>
              </div>
              <div data-aos="flip-down" className="sm:block flex flex-col mt-10 text-center">
                <a
                  href="/portfolio"
                  target="_blank"
                  className="github-star sm:ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-blueGray-700 active:bg-blueGray-600 uppercase text-sm shadow hover:shadow-lg"
                >
                  <span>View All Works</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
     

     
      <Footer />
    </>
  );
}
