/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

export default function CRMS() {
    return (
        <>
            <IndexNavbar fixed />

            <div data-aos="fade-in" className="page-title-area">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="page-title-content">
                                <h2 data-aos="zoom-out">Online Accounting System</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className=" md:mt-10 pb-20 relative bg-blueGray-100">

                <div className="container mx-auto px-4 pb-20 pt-20">
                    <div className="items-center flex flex-wrap">
                        <div className="w-full md:w-10/12 ml-auto px-12 md:px-4">
                            <div className="md:pr-12">
                                {/* <h3 data-aos="fade-up" className="text-3xl font-semibold text-center">
                Consignment Management System
                </h3> */}
                                <div data-aos="zoom-out-up" className="md:w-8/12 m-auto">
                                    <img
                                        alt="..."
                                        className="max-w-full"
                                        src={require("assets/img/accounting.png").default}
                                    />
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section>

            <section className="relative py-20">
                <div
                    className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
                    style={{ transform: "translateZ(0)" }}
                >
                    <svg
                        className="absolute bottom-0 overflow-hidden"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="none"
                        version="1.1"
                        viewBox="0 0 2560 100"
                        x="0"
                        y="0"
                    >
                        <polygon
                            className="text-white fill-current"
                            points="2560 0 2560 100 0 100"
                        ></polygon>
                    </svg>
                </div>

                <div className="container mx-auto px-4">
                    <div className="flex flex-wrap">
                        <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                            <div className="md:pr-12">
                                <h3 data-aos="flip-down" className="text-3xl font-semibold">Accounting Master</h3>
                                <ul className="list-none mt-6">
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                    Account Head
                          </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                    Account Group
                          </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                    Account Sub-Group
                          </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                    Accounts Ledger
                          </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                    Cost Center Group
                          </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                    Cost Center Ledger
                          </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                    Financial Year
                          </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                    Reconcile Bank
                          </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                    Auditing
                          </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                    General Settings
                          </h4>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                            <div className="md:pr-12">

                                <h3 data-aos="flip-down" className="text-3xl font-semibold">Accounting Transaction</h3>
                                <ul className="list-none mt-6">
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                    Journal Voucher
                          </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                    Payment Voucher

                          </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                    Receipt Voucher
                          </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                    Purchase Voucher
                          </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                    Sales Voucher
                          </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                    Contra
                          </h4>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                            <div className="md:pr-12">

                                <h3 data-aos="flip-down" className="text-3xl font-semibold">Accounts Reports</h3>
                                <ul className="list-none mt-6">
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">

                                                    Journel Report
                          </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">

                                                    Ledger Report

                          </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">

                                                    Cost Center Ledger Report
                          </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">

                                                    Trial Balance Report
                          </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">

                                                    Balance Sheet Report
                          </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                    Profit and Loss account
                                                </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                Day Book

                                                </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                Reconcile Bank
                                                </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                Recivable
                                                </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                Payable
                                                </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                Ledger Book Details
                                                </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                Cost Center Break-up Report 
                                                </h4>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

         
            <Footer />
        </>
    );
}
