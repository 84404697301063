/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import Mailer from "components/mailer.js";

export default function HRMS() {
  return (
    <>
      <IndexNavbar fixed />

      <div data-aos="fade-in" className="page-title-area">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2 data-aos="zoom-out">Contact Us</h2>
              </div>
              </div>
            </div>
          </div>
        </div>
    
      {/* <Mailer /> */}
      <div className="contact-section ptb-100" id="contactus">
          <div className="container">
            <div className="row align-items-center">
              <div data-aos="zoom-out-up" className="col-lg-6"><div className="contact-image">
              <img
               alt="..."
               className="max-w-full" src={require("assets/img/contactus.png").default}
             />
                </div>
              </div>
              <div data-aos="zoom-out-up" className="col-lg-6">
              <Mailer />  
              </div>
            </div>
          </div>
        </div>


      <Footer />
    </>
  );
}
