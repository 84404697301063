/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import Aos from 'aos';
import 'aos/dist/aos.css';
import 'views/Particle.js';
import Mailer from "components/mailer.js";


import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

export default function Index() {
  useEffect(() => {
    Aos.init({ duration: 1000 });

  }, []);

  return (
    <>
      {/* <IndexNavbar fixed /> */}

      <div className="main-banner-two">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container-fluid">
              <canvas id="canvas" className="canvas"></canvas>
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-12">
                  <div className="main-banner-content">


                    <div className="main-banner-image">
                      <img
                        alt="..."
                        src={require("assets/img/makan_erp_logo_xl.png").default}
                        className=" h-210 max-w-110-px"
                      />
                    </div>

                    <h1 className="font-extra-bold c-blue" data-aos="zoom-out">Makan ERP</h1>

                    <p data-aos="fade-up" className="mt-4 text-lg leading-relaxed text-blueGray-500">
                      At Greeniit, we provide end-to-end application development solutions starting from initial business case analysis to post-implementation support. We leverage the best of technologies and practices to ensure high quality business applications.</p>
                    <div className="banner-btn">

                      <a data-aos="flip-down" className="default-btn" href="#contactus">Contact Us<span></span></a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="main-banner-image">
                    <img
                      alt="..."
                      className="max-w-full"

                      src={require("assets/img/erp-software.png").default}
                    />

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="creative-shape">
          <img src={require("assets/images/home-shape-2-45ee2697b879b410f36107f5fc7773ee.png").default} alt="..." />
        </div>
        <div className="shape-dot">
          <img src={require("assets/images/main-banner-dot-61404125f1f3ae6cf9de3cb70ba8e8f6.png").default} alt="..." />
          <img src={require("assets/images/main-banner-dot-61404125f1f3ae6cf9de3cb70ba8e8f6.png").default} />
        </div>
      </div>


      <section className="services-section pt-100 spt-50 spb-0 pb-70">
        <div className="container">
          <div className="section-title">
            <h2 className="font-semibold" data-aos="fade-up">Makan ERP Solutions</h2>
          </div>
          <div className="row p-b-100">
            <div className="col-lg-4 col-md-6" data-aos="zoom-out-up">
              <div className="single-services-box">
                <img
                  alt="..."
                  className="max-w-full"
                  style={{
                    transform:
                      "scale(1) perspective(1040px) rotateY(-11deg) rotateX(2deg) rotate(2deg)",
                  }}
                  src={require("assets/img/crm.png").default}
                />
                <h2>Customer Relationship Management Solution</h2>
                <div className="sm:block flex flex-col mt-10 text-center">
                  <a data-aos="zoom-in"
                    href="#"
                    className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-2 bg-yellow  uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                  >
                    Demo
                </a>
                  
                  <Link data-aos="zoom-in"
                to="/CRMS"
                className="github-star sm:ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-blueGray-700 active:bg-blueGray-600 uppercase text-sm shadow hover:shadow-lg">
                View More
              </Link>
                </div>

              </div>
            </div>
            <div className="col-lg-4 col-md-6" data-aos="zoom-out-up">
              <div className="single-services-box">
                <img
                  alt="..."
                  className="max-w-full"
                  style={{
                    transform:
                      "scale(1) perspective(1040px) rotateY(-11deg) rotateX(2deg) rotate(2deg)",
                  }}
                  src={require("assets/img/hrm_software.png").default}
                />
                <h2>Human Resource Management Solution</h2>
                <div className="sm:block flex flex-col mt-10 text-center">
                  <a data-aos="zoom-in"
                    href="#"
                    className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-2 bg-yellow  uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                  >
                    Demo
                </a>
                <Link data-aos="zoom-in"
                to="/HRMS"
                className="github-star sm:ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-blueGray-700 active:bg-blueGray-600 uppercase text-sm shadow hover:shadow-lg">
                View More
              </Link>
                </div>

              </div>
            </div>
            <div className="col-lg-4 col-md-6" data-aos="zoom-out-up">
              <div className="single-services-box">
                <img
                  alt="..."
                  className="max-w-full"
                  src={require("assets/img/accounting.png").default}
                />
                <h2>Online Accounting System</h2>
                <div className="sm:block flex flex-col mt-10 text-center">
                  <a data-aos="zoom-in"
                    href="#"
                    className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-2 bg-yellow  uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                  >
                    Demo
                </a>
                <Link data-aos="zoom-in"
                to="./Accounting"
                className="github-star sm:ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-blueGray-700 active:bg-blueGray-600 uppercase text-sm shadow hover:shadow-lg">
                View More
              </Link>
                </div>

              </div>
            </div>
            <div className="col-lg-4 col-md-6" data-aos="zoom-out-up">
              <div className="single-services-box">
                <img
                  alt="..."
                  className="max-w-full"
                  src={require("assets/img/pos.png").default}
                />
                <h2>Online POS (Point Of Sales)</h2>
                <div className="sm:block flex flex-col mt-10 text-center">
                  <a data-aos="zoom-in"
                    href="#"
                    className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-2 bg-yellow  uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                  >
                    Demo
                </a>
                <Link data-aos="zoom-in"
                to="./POS"
                className="github-star sm:ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-blueGray-700 active:bg-blueGray-600 uppercase text-sm shadow hover:shadow-lg">
                View More
              </Link>
                </div>

              </div>
            </div>
            <div className="col-lg-4 col-md-6" data-aos="zoom-out-up">
              <div className="single-services-box">
                <img
                  alt="..."
                  className="max-w-full"
                  src={require("assets/img/property.png").default}
                />
                <h2>Property Management System</h2>
                <div className="sm:block flex flex-col mt-10 text-center">
                  <a data-aos="zoom-in"
                    href="#"
                    className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-2 bg-yellow  uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                  >
                    Demo
                </a>
                <Link data-aos="zoom-in"
                to="./Property"
                className="github-star sm:ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-blueGray-700 active:bg-blueGray-600 uppercase text-sm shadow hover:shadow-lg">
                View More
              </Link>
                </div>

              </div>
            </div>
            <div className="col-lg-4 col-md-6" data-aos="zoom-out-up">
              <div className="single-services-box">
                <img
                  alt="..."
                  className="max-w-full"
                  src={require("assets/img/pms.png").default}
                />
                <h2>Project Management System</h2>
                <div className="sm:block flex flex-col mt-10 text-center">
                  <a data-aos="zoom-in"
                    href="#"
                    className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-2 bg-yellow  uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                  >
                    Demo
                </a>
                <Link data-aos="zoom-in"
                to="./PMS"
                className="github-star sm:ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-blueGray-700 active:bg-blueGray-600 uppercase text-sm shadow hover:shadow-lg">
                View More
              </Link>
                </div>

              </div>
            </div>
            <div className="col-lg-4 col-md-6" data-aos="zoom-out-up">
              <div className="single-services-box">
                <img
                  alt="..."
                  className="max-w-full"
                  src={require("assets/img/hotel_management.png").default}
                />
                <h2>Hotel Management System</h2>
                <div className="sm:block flex flex-col mt-10 text-center">
                  <a data-aos="zoom-in"
                    href="#"
                    className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-2 bg-yellow  uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                  >
                    Demo
                </a>
                <Link data-aos="zoom-in"
                to="./Hotel_management"
                className="github-star sm:ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-blueGray-700 active:bg-blueGray-600 uppercase text-sm shadow hover:shadow-lg">
                View More
              </Link>
                </div>

              </div>
            </div>
            <div className="col-lg-4 col-md-6" data-aos="zoom-out-up">
              <div className="single-services-box">
                <img
                  alt="..."
                  className="max-w-full"

                  src={require("assets/img/consignment.png").default}
                />
                <h2>Consignment Management System</h2>
                <div className="sm:block flex flex-col mt-10 text-center">
                  <a data-aos="zoom-in"
                    href="#"
                    className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-2 bg-yellow  uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                  >
                    Demo
                </a>
                <Link data-aos="zoom-in"
                to="./Consignment"
                className="github-star sm:ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-blueGray-700 active:bg-blueGray-600 uppercase text-sm shadow hover:shadow-lg">
                View More
              </Link>
                </div>

              </div>
            </div>
            <div className="col-lg-4 col-md-6" data-aos="zoom-out-up">
              <div className="single-services-box">
                <img
                  alt="..."
                  className="max-w-full"
                  src={require("assets/img/clinic.png").default}
                />
                <h2>Clinical Management System</h2>
                <div className="sm:block flex flex-col mt-10 text-center">
                  <a data-aos="zoom-in"
                    href="#"
                    className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-2 bg-yellow  uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                  >
                    Demo
                </a>
                <Link data-aos="zoom-in"
                to="./Clinic"
                className="github-star sm:ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-blueGray-700 active:bg-blueGray-600 uppercase text-sm shadow hover:shadow-lg">
                View More
              </Link>
                </div>

              </div>
            </div>
            <div className="col-lg-4 col-md-6" data-aos="zoom-out-up">
              <div className="single-services-box">
                <img
                  alt="..."
                  className="max-w-full"
                  src={require("assets/img/school-erp.png").default}
                />
                <h2>School Management System</h2>
                <div className="sm:block flex flex-col mt-10 text-center">
                  <a data-aos="zoom-in"
                    href="#"
                    className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-2 bg-yellow  uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                  >
                    Demo
                </a>
                <Link data-aos="zoom-in"
                to="./School_management_system"
                className="github-star sm:ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-blueGray-700 active:bg-blueGray-600 uppercase text-sm shadow hover:shadow-lg">
                View More
              </Link>
                </div>

              </div>
            </div>
            <div className="col-lg-4 col-md-6" data-aos="zoom-out-up">
              <div className="single-services-box">
                <img
                  alt="..."
                  className="max-w-full"
                  src={require("assets/img/vehicle_maintenance.png").default}
                />
                <h2>Vehicle Maintenance System</h2>
                <div className="sm:block flex flex-col mt-10 text-center">
                  <a data-aos="zoom-in"
                    href="#"
                    className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-2 bg-yellow  uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                  >
                    Demo
                </a>
                <Link data-aos="zoom-in"
                to="./Vehicle_maintenance"
                className="github-star sm:ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-blueGray-700 active:bg-blueGray-600 uppercase text-sm shadow hover:shadow-lg">
                View More
              </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6" data-aos="zoom-out-up">
              <div className="single-services-box">
                <img
                  alt="..."
                  className="max-w-full"
                  src={require("assets/img/inventory.png").default}
                />
                <h2>Inventory Management System</h2>
                <div className="sm:block flex flex-col mt-10 text-center">
                  <a data-aos="zoom-in"
                    href="#"
                    className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-2 bg-yellow  uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                  >
                    Demo
                </a>
                <Link data-aos="zoom-in"
                to="./Inventory"
                className="github-star sm:ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-blueGray-700 active:bg-blueGray-600 uppercase text-sm shadow hover:shadow-lg">
                View More
              </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6" data-aos="zoom-out-up">
              <div className="single-services-box">
                <img
                  alt="..."
                  className="max-w-full"
                  src={require("assets/img/Makan_logistics_management.png").default}
                />
                <h2>Logistics Management System</h2>
                <div className="sm:block flex flex-col mt-10 text-center">
                  <a data-aos="zoom-in"
                    href="#"
                    className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-2 bg-yellow  uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                  >
                    Demo
                </a>
                <Link data-aos="zoom-in"
                to="./Courier"
                className="github-star sm:ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-blueGray-700 active:bg-blueGray-600 uppercase text-sm shadow hover:shadow-lg">
                View More
              </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6" data-aos="zoom-out-up">
              <div className="single-services-box">
                <img
                  alt="..."
                  className="max-w-full"
                  src={require("assets/img/makan_support_management_system.png").default}
                />
                <h2>Support Management System</h2>
                <div className="sm:block flex flex-col mt-10 text-center">
                  <a data-aos="zoom-in"
                    href="#"
                    className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-2 bg-yellow  uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                  >
                    Demo
                </a>
                <Link data-aos="zoom-in"
                to="./Support_Management_System"
                className="github-star sm:ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-blueGray-700 active:bg-blueGray-600 uppercase text-sm shadow hover:shadow-lg">
                View More
              </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>









      <div className="contact-section ptb-100" id="contactus">
          <div className="container">
            <div className="row align-items-center">
              <div data-aos="zoom-out-up" className="col-lg-6"><div className="contact-image">
              <img
               alt="..."
               className="max-w-full" src={require("assets/img/contactus.png").default}
             />
                </div>
              </div>
              <div data-aos="zoom-out-up" className="col-lg-6">
              <Mailer />  
              </div>
            </div>
          </div>
        </div>


        

     


     
      <Footer />
    </>
  );
}
